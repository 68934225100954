$blueShadeAlt: #1A80FF;
$blueShade1: #0d2340;
$blueShade2: #1b3b64;
$blueShade3: #226495;
$blueShade4: #2c82ca;
$blueShade5: #3da7fb;
$blueShade6: #8fb3d9;
$greyShade1: #545b61;
$greyShade2: #8b9095;
$greyShade3: #dfe1e5;
$greenShade1: #1DB8BE;
$greenShade2: #23D3D9;
$greenShade3: #2BE3EA;
$greenShade4: #71F3F8;
$greenShade5: #B7FCFF;
$orangeShade1: #f8d824;
$orangeShade2: #f2a831;
$orangeShade3: #f77f00;
$orangeShade4: #f74a21;
$red1: #f74a21;
$navColor: #1e4576;
$headerColor: #091c36;
$white: #fff;

$primaryColor: #2BE3EA;
$alertColor: #f74a21;

$headerTextColorForDark: #3DA7FB;
$headerTextColorForLight: #1A80FF;
// $headerTextColorForDark: fuchsia;
// $headerTextColorForLight: deeppink;

$buttonColor: #0D2340;
$buttonBackgroundColor: #DFE1E5;

$buttonSecondaryColor: #0D2340;
$buttonSecondaryBackgroundColor: #DFE1E5;
$buttonSecondaryHoverColor: #0D2340;
$buttonSecondaryHoverBackgroundColor: #1A80FF;
