$formHeight: 660px;
$formHeightGrants: 660px;
$globalPadding: 30px;

#requests-forms-root {
    @extend .forms-root;
    background: white;
}

#applications-forms-root {
    @extend .forms-root;
    background: #f2f2f7;
}

.forms-button-container {
    margin-bottom: 20px;
}

.forms-buttons {
    padding-top: $globalPadding;
}

.forms-root {
    height: $formHeight;
    padding: $globalPadding;
}

@media only screen and (max-width: 959px) {
 .forms-root {
     height: 100%;
 }
}

// Grants forms
#requests-forms-root-grants {
    @extend .forms-root-grants;
    background: white;
}

#applications-forms-root-grants {
    @extend .forms-root-grants;
    background: #f2f2f7;
}


.forms-root-grants {
    height: 100%;
    padding: $globalPadding;
}


.form-faux iframe {
  background-color: rgba(22, 22, 22, .02);
  border-width: 0px;
  height: 90%;
  max-width: 1080px;
}
