@import "./variables.scss";

// #chart1 > div.VictoryContainer {
//   height: 75% !important;
//   width: auto !important;
//   max-width: 318px;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: -30px;

//   outline: 4px dashed orange;
//   outline-offset: -2px;
// }

// #summary-contributions-chart > div.VictoryContainer {
//   height: 75% !important;
//   width: auto !important;
//   max-width: 318px;
//   margin-left: auto;
//   margin-right: auto;

//   outline: 4px dashed orange;
//   outline-offset: -2px;
// }

// #chart3 > svg {
//   height: 75% !important;
//   width: auto !important;
//   max-width: 318px;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: -30px;

//   outline: 4px dashed orange;
//   outline-offset: -2px;
// }

// #chart4 > svg {
//   height: 75% !important;
//   width: auto !important;
//   max-width: 318px;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 40px;

//   outline: 4px dashed orange;
//   outline-offset: -2px;
// }

// #chart-stack-1-bar-0-labels-0 > tspan:nth-child(1) {
//   font-size: 24px !important;
//   letter-spacing: 1px;
//   fill: white !important;
//   font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont !important;
//   font-weight: 700;

//   outline: 4px dashed red;
//   outline-offset: -2px;
// }

// #chart-stack-1-bar-0-labels-0 > tspan:nth-child(3) {
//   font-size: 13px !important;
//   font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont !important;
//   font-style: italic;
// }

// SUMMARY/Cards
.summary-row {
  // outline: 4px dashed orange;
  // outline-offset: -2px;

  .charts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    position: relative;
    background-color: white;
    height: calc(100% - 20px);
    padding: 10px;

    &.buttonless {
      padding-bottom: 70px;
      height: calc(100% - 80px);
    }

    &>* {
      flex-grow: 1;

      // outline: 4px dashed deeppink;
      // outline-offset: -2px;
    }

    .legend {
      flex-grow: 0;
    }

    .MuiButton-contained {
      flex-grow: 0;
    }

    &:hover {
      .MuiButton-contained {
        background-color: $buttonSecondaryHoverBackgroundColor;
        color: #fff;
      }
      hr.MuiDivider-root {
        background-color: $buttonSecondaryHoverBackgroundColor !important;
        opacity: 1 !important;
        width: 100% !important;
      }
    }
  }
}

// ASSETS/MainChart
.asset-chart-tt-amount {
  font-size: 16px !important;
}
.asset-chart-tt-label {
  font-size: 12px !important;
  opacity: 0.8 !important;
}
.apexcharts-tooltip {
  background: $blueShade2 !important;
  border: none !important;
  color: white;
  padding: 15px;
}
.apexcharts-xaxistooltip-bottom {
  display: none;
}
