@import "./variables.scss";

.list-icon-btn {
  background-color: $white;
  color: $blueShade2;
  height: 60px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 0 15px 0 15px;
  transition: 0.15s ease-in-out;
  text-decoration: none;

  small {
    color: $greyShade2;
    margin-top: 0.25em;
  }

  .list-icon-btn-ti {
    display: none;
  }

  &[href]:active, &[href]:hover, &[href]:focus {
    background-color: $primaryColor !important;
    border-radius: 30px;
    color: $blueShade2;
    cursor: pointer;
    outline: none;
    padding: 0 19px 0 19px;
    transition: 0.15s ease-in-out;

    small {
      color: $blueShade3;
    }

    .list-icon-btn-ti {
      display: inline;
      transition: 0.15s ease-in-out;
    }
  }
}
