.accordion-root {
    background-color: #f6f6f6;
}

.accordion-btn:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}

.open-accordion-body {
    margin-left: 20px;
    margin-right: 20px;
}

.rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    animation: rotation .3s ease-in-out;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(45deg);
    }
}

.rotate-back {
    animation: rotationb .3s ease-in-out;
}

@keyframes rotationb {
    from {
        transform: rotate(45deg);
    }
    to {
        transform: rotate(0deg);
    }
}