@import './variables.scss';


#docs-root {
    padding-top: 30px;
}

.docs-side-root {
    padding-bottom: 30px;
    min-height: 660px;
}

.doc-pb {
    padding-bottom: 20px;
}

#docs-title-divider {
    margin-bottom: 30px;
    margin-top: 50px;
}

.rud-link {
    @extend .flex-ac;
    margin-bottom: 8px;
    .forward-icon {
        fill: white;
        height: 15px;
        width: 15px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 959px) {
    #docs-right-side {
        display: none;
    }
}
