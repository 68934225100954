.hamburger-menu {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    transition: all .5s ease-in-out;
    // border: 3px solid #fff
}

.hamburger-menu-btn {
    width: 30px;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    // box-shadow: 0 2px 5px #000;
    transition: all .5s ease-in-out;
}

.hamburger-menu-btn::before,
.hamburger-menu-btn::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    // box-shadow: 0 2px 5px #000;
    transition: all .5s ease-in-out;
}
.hamburger-menu-btn::before {
    transform: translateY(-12px);
}

.hamburger-menu-btn::after {
    transform: translateY(12px);
}

.hamburger-menu-open .hamburger-menu-btn {
    transform: translateX(-50px);
    background: transparent !important;
    box-shadow: none;
}
.hamburger-menu-open .hamburger-menu-btn::before {
    transform: rotate(45deg) translate(35px, -35px);
}
.hamburger-menu-open .hamburger-menu-btn::after {
    transform: rotate(-45deg) translate(35px, 35px);
}

.hamburger-menu-list-open {
    transition: all .5s ease-in-out;
    transform: translateY(100px);
}

// .hamburger-dropdown-li {
//     padding: 11px 21px !important;
// }
// .hamburger-dropdown-link {
//     text-decoration: none;
// }
