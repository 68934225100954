@import "./variables.scss";

.full-screen-loader {
  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $headerColor;
  height: calc(100vh - 90px);
  width: 100vw;
  z-index: 999;
}

// .fadeout {
//     animation: fadeOut 1s;
// }

// @keyframes fadeOut {
//     0% {
//         opacity: .8;
//     }
//     100% {
//         opacity: 0;
//     }
// }
