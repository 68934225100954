@import "./variables.scss";

#login-image {
  background-image: url("/images/clouds.jpg");
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  z-index: -1;
  position: fixed;
}

#login-t1 {
  position: absolute;
  width: 600px;
  left: 0;
  background-color: $blueShade1;
  z-index: 1;
  min-height: 100vh;
}
#login-t2-container {
  @extend .flex-c-ac;
  height: 100vh;
}

#login-t2 {
  display: inline-block;
  background-color: rgba($color: $blueShade1, $alpha: .7);
  padding: 50px 30px;
}

.login-form {
  background-color: white;
  width: 550px;
  max-width: 80%;
  padding: 0 25px;
}

@media only screen and (max-width: 600px) {
  #login-t1 {
    width: 100%;
  }
  #login-t2 {
    width: 100%;
    padding: 0;
    background: none;
  }
  #login-t2-container {
    background-color: rgba($color: $blueShade1, $alpha: .7);
  }
}
