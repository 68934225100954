@import "./variables";

// html, body, #root {
//   height: 100%;
// }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont;
  font-size: 18px;
  overflow-x: hidden;
  overflow-y: scroll;
  color: $blueShade1;
  background-color: $headerColor;
}

a, .faux-link {
  color: $blueShade4;
  text-decoration: underline;
  &:active, &:hover, &:focus {
    color: $blueShade5;
    text-decoration: none;
  }
}
.faux-link {
  cursor: pointer;
}

main {
  .MuiGrid-container + .MuiGrid-container {
    margin-top: 15px;
  }
}

tspan {
  font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
  &.center {
    align-items: center;
  }
}

.flex-sb-ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-sb-ab {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex-c-ac {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.flex-ac {
  display: flex;
  align-items: center;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.flex-c {
  display: flex;
  justify-content: center;
}

.hidden {
  display: none;
}

.my-mb1 {
  margin-bottom: 8px;
}
.my-mb2 {
  margin-bottom: 16px;
}
.my-mt2 {
  margin-top: 16px;
}
.my-mb3 {
  margin-bottom: 24px;
}

.MuiDialog-paper {
  opacity: 0.94;
}

.header-text-for-dark {
  color: $headerTextColorForDark;
}
.header-text-for-light {
  color: $headerTextColorForLight;
}

.text-primary {
  color: red;
}
.bg-primary {
  background-color: $primaryColor;
}
.text-alert {
  color: $alertColor;
}
.bg-alert {
  background-color: $alertColor;
}

.blue-s1 {
  color: $blueShade1;
}
.blue-s1-bg {
  background-color: $blueShade1;
}
.blue-s2 {
  color: $blueShade2;
}
.blue-s2-bg {
  background-color: $blueShade2;
}
.blue-s3 {
  color: $blueShade3;
}
.blue-s3-bg {
  background-color: $blueShade3;
}
.blue-s4 {
  color: $blueShade4;
}
.blue-s4-bg {
  background-color: $blueShade4;
}
.blue-s5 {
  color: $blueShade5;
}
.blue-s5-bg {
  background-color: $blueShade5;
}
.blue-s6 {
  color: $blueShade6;
}
.blue-s6-bg {
  background-color: $blueShade6;
}
.grey-s1 {
  color: $greyShade1;
}
.grey-s1-bg {
  background-color: $greyShade1;
}
.grey-s2 {
  color: $greyShade2;
}
.grey-s2-bg {
  background-color: $greyShade2;
}
.grey-s3 {
  color: $greyShade3;
}
.grey-s3-bg {
  background-color: $greyShade3;
}
.green-s1 {
  color: $greenShade1;
}
.green-s1-bg {
  background-color: $greenShade1;
}
.green-s2 {
  color: $greenShade2;
}
.green-s2-bg {
  background-color: $greenShade2;
}
.green-s3 {
  color: $greenShade3;
}
.green-s3-bg {
  background-color: $greenShade3;
}
.orange-s1 {
  color: $orangeShade1;
}
.orange-s1-bg {
  background-color: $orangeShade1;
}
.orange-s2 {
  color: $orangeShade2;
}
.orange-s2-bg {
  background-color: $orangeShade2;
}
.orange-s3 {
  color: $orangeShade3;
}
.orange-s3-bg {
  background-color: $orangeShade3;
}
.orange-s4 {
  color: $orangeShade4;
}
.orange-s4-bg {
  background-color: $orangeShade4;
}
.red-s1 {
  color: $red1;
}
.red-s1-bg {
  background-color: $red1;
}
.blackish {
  color: #292929;
}
.no-overflow {
  overflow: hidden;
}

#four04 {
  @extend .flex-c-ac;
  height: 80vh;
  overflow: hidden;
  text-align: center;
  h1 {
    font-size: 80px;
  }
}

.stretch {
  align-items: stretch;
}

.w-100 {
  width: 100%;
}

.rm-padding {
  padding: 0px !important;
}

.italic {
  font-style: italic;
}

.f-700 {
  font-weight: 700;
}

.f-400 {
  font-weight: 400;
}
.f-300 {
  font-weight: 300;
}

.xxl-text {
  font-size: 48px;
}

.xl-text {
  font-size: 32px;
}

.lg-text {
  font-size: 24px;
}

.md-text {
  font-size: 20px;
}

.rg-text {
  font-size: 18px;
}

.sm-text {
  font-size: 14px;
}

.default-container {
  padding: 0 20px;
}

.section-border {
  @extend .bt-sm-dn;
  @extend .bl-md-up;
}

.side-border-text {
  display: block;
  border-left: 2px solid $primaryColor;
  margin-left: 16px;
  padding-left: 16px;
  color: #292929;
  font-weight: 300;
}

.m-0 {
  margin: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mt-0 {
  margin-top: 0px;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}

.px-1 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.t-center {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .bt-sm-dn {
    border-top: 1px solid rgb(204, 204, 204);
  }
}
@media only screen and (min-width: 768px) {
  .bl-md-up {
    border-left: 1px solid rgb(204, 204, 204);
  }
}


@media only screen and (max-width: 1279px) {
  .bt-lg-dn {
    border-top: 1px solid rgb(204, 204, 204);
  }
}
@media only screen and (max-width: 599px) {
  .bt-xs-dn {
    border-top: 1px solid rgb(204, 204, 204);
  }
}
@media only screen and (min-width: 1280px) {
  .bl-lg {
    border-left: 1px solid rgb(204, 204, 204);
  }
}
@media only screen and (min-width: 600px) {
  .bl-md-up {
    border-left: 1px solid rgb(204, 204, 204);
  }
}

.my-select-container {
  min-width: 170px;
  min-height: 40px;
}
@media only screen and (max-width: 767px) {
  .chart-info {
    flex-direction: column;

    .my-select-container {
      position: relative;
      padding-top: 20px;
    }
  }
}
@media only screen and (min-width: 768px) {
  .my-select-container {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
