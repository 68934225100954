.add-padding {
  min-height: 280px;
}

.add-padding-inner-components {
  justify-content: flex-end;
}

@media only screen and (max-width: 959px) {
    .add-padding {
        min-height: 480px;
        // padding-top: 30px;
        // padding-bottom: 30px;
    }
    .add-padding-inner-components {
      justify-content: flex-start;
        padding-left: 20px;
        padding-right: 20px;
    }
    .header-grid-title {
      padding-bottom: 1rem;
    }
}


