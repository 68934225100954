@import "./variables.scss";

.rlc-text-container {
    padding-bottom: 30px;
}

.rlc-title-divider {
    margin-bottom: 25px;
    margin-top: 50px;
}

.rl-container {
    background: #f2f2f7;
    padding-bottom: 30px;
}

.rlc-icon {
    color: $headerTextColorForLight;
    padding-right: 10px;
}

.rlc-link {
    color: black;
    text-decoration: none;

}
.rlc-link-container {
    padding: 8px 0px;
    border-bottom: 1px solid #dfe1e5;
}
