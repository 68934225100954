@import "./variables.scss";

#nav-spacing {
    height: 90px;
    width: 100vw;
}

#nav {
    top: 0;
    height: 90px;
    position: fixed;
    width: 100%;
    background-color: $navColor;
    color: white;
    z-index: 500;
}

.desktop-nav {
  display: none !important;
  height: 90px;
}

.mobile-nav {
    padding-left: 10px;
}

.nav-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 10px;
}

// .nav-styled-link {
//   outline: 4px dashed orange;
//   outline-offset: -2px;
// }
// .nav-li-link {
//   outline: 4px dashed yellow;
//   outline-offset: -2px;

//   &:hover {
//     border-top: 5px solid $primaryColor;
//   }
// }



// .nav-li {
//     display: block !important;
//     margin-top: 49px;

//     outline: 4px dashed orange;
//     outline-offset: -2px;
// }


.desktop-nav-list {
  font-size: 18px;
  font-weight: 400;
  height: 90px;
  letter-spacing: -1px;
  width: 100%;

  @media only screen and (min-width: 1380px) {
    font-size: 18px;
    letter-spacing: normal;
  }

  .MuiListItem-root {
    height: 90px;
    padding-top: 0px;
    padding-bottom: 0px;

    &.MuiListItem-gutters {
      padding: 0px;
      .MuiButtonGroup-root {
        margin-left: .75rem;
      }
      .MuiButton-label {
        white-space: nowrap;
      }
    }

    &#desktop-logout {
      margin-left: .5em;
    }

    .nav-li-logo {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      height: 90px;
      padding: 0px 8px;
    }

    .nav-li-link {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      height: 90px;
      padding: 0px 12px;
      text-decoration: none;
      white-space: nowrap;

      .nav-bottom-point {
        position: absolute;
        bottom: -18px;
        right: 0;
        left: 0;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $navColor;
        margin: 0px auto;
      }

      &:hover, &.is-active {
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 2px;
          left: 2px;
          height: 5px;
          background-color: $primaryColor;
        }
      }
    }
  }
}

.mobile-nav.nav-container {
  padding-left: 8px;
}

.mobile-nav.mobile-nav-menu {
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, .5);
  max-height: 100%;
  overflow-y: auto;

  .MuiList-root.MuiList-padding {
    padding: 0px;

    .MuiListItem-root.hamburger-dropdown-li {
      display: block;
      padding: 0px;
    }

    .MuiListItem-root.hamburger-dropdown-li .hamburger-dropdown-link, .hamburger-dropdown-li>span {
      display: block;

      background-color: $white;
      border-bottom: 1px solid $greyShade2;

      cursor: pointer;
      font-weight: 500;
      padding: 1em 1.5em;
      text-decoration: none;

      &:hover {
        background-color: $greyShade3;
        color: #0d2340;
        font-style: italic;
      }
    }

    .hamburger-dropdown-li:last-child span {
      padding-bottom: 2em;
    }
  }
}

#desktop-logout {
    white-space: nowrap;
    cursor: pointer;
}

.mobile-nav-menu {
    position: fixed;
    background: #e5e5e5;
    width: 100%;
    z-index: 1000;
}

@media only screen and (min-width: 1200px) {
    .desktop-nav {
        display: flex !important;
    }
    .mobile-nav {
        display: none !important;
    }
}
