@import "./variables.scss";

.my-select {
  position: absolute;
  background-color: $white;
  max-width: 100%;
  width: 100%;
  min-height: 40px;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.my-select-root label {
  font-weight: 700;
  font-size: 14px;
}

.my-select-root {
  position: relative;
  z-index: 11;
}

.my-select-option {
  width: calc(100% + 23px);
  padding-top: 5px;
  padding-bottom: 5px;
  left: -10px;
  position: relative;
  padding-left: 12px;
}
.my-select-option:hover {
  background: $greyShade3;
}
.my-select-option:active {
  background: grey;
}
